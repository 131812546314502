import {toast} from '@cashiaApp/web-components';
import {useCallback, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {ReactComponent as CloseIcon} from '../../../assets/icons/close_circle_icon.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete_white_icon.svg';
import Modal from '../../../components/tailwind/Modal';
import Spinner from '../../../components/tailwind/Spinner';
import TextInput from '../../../components/tailwind/TextInput';
import {
  GetReelsQuery,
  useDeleteReelMutation,
  useGetReelQuery,
  GetReelsDocument,
} from '../../../graphql/generated';
import EmptyProductsDisplay from '../components/EmptyProductsDisplay';
import ProductsDisplay from '../components/ProductsDisplay';

const ReelsAnalytics = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const {id} = useParams();
  const [deleteReelMutation, {loading, error}] = useDeleteReelMutation();

  const {data: reel, loading: reelLoading} = useGetReelQuery({
    variables: {id: id || ''},
    skip: !id,
  });

  const deleteReel = useCallback(async () => {
    const res = await deleteReelMutation({
      variables: {
        id: id || '',
      },
      update: (cache, result) => {
        const variables = {
          input: {
            cursor: {
              first: 20,
            },
          },
        };
        if (!result.data?.deleteReel) return;
        const cached = cache.readQuery<GetReelsQuery>({
          query: GetReelsDocument,
          variables,
        });
        cache.writeQuery({
          query: GetReelsDocument,
          variables,
          data: {
            ...cached,
            reels: {
              ...cached?.reels,
              edges: [
                ...(cached?.reels.edges || []).filter(
                  (item) => item.node.id !== id
                ),
              ],
              pageInfo: {...cached?.reels.pageInfo},
            },
          },
        });
      },
    });
    if (res.data?.deleteReel) {
      toast.success(`'${reel?.reel?.name || 'Reel'}' is deleted`);
      navigate('/reels');
    }
  }, [deleteReelMutation, id, navigate, reel?.reel?.name]);

  if (loading) {
    return (
      <div className="mt-2 flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="mt-3 rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }
  return (
    <div className="w-full h-full flex-col flex gap-10 pl-24 pb-10 overflow-y-scroll no-scrollbar">
      <div className="w-full h-20 p-[10px] items-center flex justify-between">
        <div className="flex flex-col">
          <div className="hidden md:flex mt-2">
            <div className="flex text-foggy underline-hover pt-[30px]" key="1">
              <p
                className="text-foggy cursor-pointer hover:underline"
                onClick={() => navigate('/reels')}>
                Reels
              </p>
              <span className="text-foggy mx-2">{'>'}</span>
              <p className="text-foggy cursor-pointer hover:underline">
                Reels Analytics
              </p>
            </div>
          </div>
          <h1 className="text-neutral-800 text-[34px] font-semibold pt-[10px]">
            Reel Video
          </h1>
        </div>
      </div>
      {reelLoading ? (
        <Spinner className="h-[40px] mt-20 w-full" />
      ) : (
        <div key={reel?.reel.id}>
          <div className="flex h-full w-full gap-14">
            <div className="flex justify-center items-center w-[400px] h-[580px] rounded-[10px] border-2 border-dashed border-neutral-200">
              <div className="flex flex-col h-full gap-7 justify-center items-center">
                <img
                  src={reel?.reel.thumbnail.large || ''}
                  className="w-[225px] h-[485px] object-cover rounded-[15px] border-4 border-neutral-800"
                />
              </div>
            </div>
            <div className="flex w-[calc(100svw-400px)]">
              <div className="w-[90%] flex flex-col gap-12">
                <div className="flex flex-col items-end">
                  <button
                    className="w-[109px] h-[46px] py-[10px] transition duration-200 bg-cashiaBlue text-white select-none cursor-pointer rounded-lg justify-center gap-2.5 inline-flex hover:bg-cyan-600"
                    onClick={() =>
                      navigate(`/reels/edit/${reel?.reel.id || ''}`)
                    }>
                    Edit Reel
                  </button>
                </div>
                <div className="flex flex-col gap-1">
                  <p className="w-full text-neutral-800 text-xl font-semibold font-['Metropolis']">
                    Caption
                  </p>
                  <TextInput
                    containerStyle="h-12"
                    value={reel?.reel.name}
                    disabled
                  />
                </div>
                <div className="flex w-full flex-col gap-1">
                  <p className="text-neutral-800 text-xl font-semibold font-['Metropolis']">
                    Display Products
                  </p>
                  {reel?.reel.products.length ? (
                    <ProductsDisplay
                      omitActions
                      products={reel?.reel.products}
                    />
                  ) : (
                    <EmptyProductsDisplay
                      icon={
                        <div
                          className="bg-[#E0F8FF] cursor-pointer text-cashiaBlue flex justify-center items-center font-bold text-3xl w-16 h-16 rounded-full"
                          onClick={() =>
                            navigate(
                              `/reels/edit/${reel?.reel.id || ''}/tag-products`
                            )
                          }>
                          +
                        </div>
                      }
                    />
                  )}
                </div>
                <div className="flex flex-col items-end">
                  <button
                    data-modal-target="popup-modal"
                    data-modal-toggle="popup-modal"
                    onClick={() => setShowModal(true)}
                    className="w-[154px] h-[50px]px-5 py-[15px] text-white justify-center gap-2.5 bg-red inline-flex focus:outline-none  rounded-lg text-sm text-center dark:bg-red-600"
                    type="button">
                    <DeleteIcon />
                    <p className="text-center text-neutral-50 text-base font-sm font-['Metropolis']">
                      Delete Reel
                    </p>
                  </button>
                </div>
                <div className="flex flex-col ">
                  <Modal isVisible={showModal} onClose={close}>
                    <div className="text-center">
                      <div className="flex justify-between items-center p-4 md:p-5">
                        <p className="font-medium text-lg">Delete Reel</p>
                        <CloseIcon
                          className="cursor-pointer"
                          onClick={() => setShowModal(false)}
                        />
                      </div>
                      <hr />
                      <p className="mb-3 p-4 md:p-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                        Deleting this Reel will permanently remove it. Are you
                        sure you want to proceed?
                      </p>
                      <div className="px-4 md:px-5 pb-4 md:pb-5 text-center">
                        <button
                          data-modal-hide="popup-modal"
                          type="button"
                          onClick={deleteReel}
                          className="text-white bg-red-600 w-[145px] text-center font-medium rounded-lg text-sm px-5 py-2.5 mr-3">
                          Delete
                        </button>
                        <button
                          data-modal-hide="popup-modal"
                          type="button"
                          onClick={() => setShowModal(false)}
                          className="py-2.5 px-5 ms-3 w-[145px] text-sm font-medium bg-greyish text-black rounded-lg">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ReelsAnalytics;
