import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as Likes} from '../../../assets/icons/heart.svg';
import {ReactComponent as VideoIcon} from '../../../assets/icons/video.svg';
import {ReactComponent as Views} from '../../../assets/icons/views.svg';
import Spinner from '../../../components/tailwind/Spinner';
import {useUserAuth} from '../../../utils/user';
import CursorPagination from '../../orders/components/CursorPagination';
import useGetReels from '../hook/useGetReels';

const Reels = () => {
  const navigate = useNavigate();
  const {merchant} = useUserAuth();
  const {
    loadMore,
    count,
    data: reelsDisplay,
    loading,
    error,
  } = useGetReels(merchant?.id || '');
  const reels = useMemo(() => reelsDisplay?.reels.edges, [reelsDisplay]);

  if (loading) {
    return (
      <div className="mt-2 flex h-full w-full flex-col justify-center">
        <Spinner width={45} height={45} />
      </div>
    );
  }

  if (error) {
    return (
      <h1 className="mt-3 rounded-lg bg-red-500 p-6 text-white">
        Error: {error.message}
      </h1>
    );
  }
  return (
    <div className="no-scrollbar w-full h-full">
      {loading ? (
        <div className="mt-2 flex h-full w-full flex-col justify-center">
          <Spinner width={45} height={45} />
        </div>
      ) : reels?.length ? (
        <div className="overflow-scroll no-scrollbar w-full h-[calc(100svh-134px)]">
          <div className="w-full p-[50px] items-center flex justify-between">
            <div className="flex flex-col">
              <p className="flex-1 text-neutral-800 text-[34px] font-semibold leading-tight truncate">
                Reels
              </p>
              <p>
                Your personalised showcase of products, ready to share our
                platform
              </p>
            </div>
            <button
              className="w-44 h-[50px] px-5 py-[15px] transition duration-200 bg-cashiaBlue select-none cursor-pointer rounded-xl justify-center gap-2.5 inline-flex hover:bg-cyan-600"
              onClick={() => navigate('/reels/upload')}>
              <VideoIcon className="w-5 h-5" />
              <p className="text-center text-neutral-50 text-base font-sm font-['Metropolis']">
                Add your reel
              </p>
            </button>
          </div>
          <div className="pl-[50px]">
            <ul className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 justify-between gap-[0.75rem]">
              {reels?.map((reel) => (
                <li
                  key={reel.node.id}
                  className="w-[213px] space-y-3 cursor-pointer mb-4">
                  <div
                    onClick={() =>
                      navigate(`/reels/analytic/${reel.node.id || ''}`)
                    }>
                    <div className="relative flex justify-center flex-col">
                      <img
                        src={reel.node.thumbnail.large || ''}
                        className="w-[213px] h-[333px] rounded-xl bg-cover object-cover"
                      />
                      <span className="absolute bottom-0 left-0 mt-2 mr-2 flex items-center bg-none bg-opacity-50 text-white px-2 py-1 rounded-md">
                        <Views />
                        <span className="ml-1">{reel.node.views}</span>
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-black text-base font-medium">
                      {reel.node.name}
                    </p>
                    <div className="flex justify-between">
                      <p className="flex text-[14px] text-foggy gap-1 items-center justify-center">
                        {reel.node.products.length} Products
                      </p>
                      <p className="flex text-[14px] text-foggy gap-1 items-center justify-center">
                        {reel.node.likes}
                        <Likes width={14} height={14} fill="fill-foggy" />
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex absolute lg:right-[48%] bottom-0 place-items-center space-around">
            <CursorPagination
              loadMore={loadMore}
              hasNextPage={reelsDisplay?.reels.pageInfo.hasNextPage}
              hasPrevPage={reelsDisplay?.reels.pageInfo.hasPrevPage}
              count={count}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex flex-col items-center gap-10 pt-44">
          <h1 className="text-black text-[50px] font-semibold font-['Metropolis']">
            You have no reels yet.
          </h1>
          <button
            className="w-44 h-[50px] px-5 py-[15px] transition duration-200 bg-cashiaBlue select-none cursor-pointer rounded-[30px] justify-center items-center gap-2.5 inline-flex hover:bg-cyan-600"
            onClick={() => navigate('/reels/upload')}>
            <VideoIcon className="w-5 h-5" />
            <p className="text-center text-neutral-50 text-base font-sm font-['Metropolis']">
              Add your reel
            </p>
          </button>
        </div>
      )}
    </div>
  );
};
export default Reels;
