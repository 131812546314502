import Button from '../../../components/tailwind/Button';
import Spinner from '../../../components/tailwind/Spinner';
import {cn} from '../../../utils/reusableFunctions';

type Props = {
  className?: string;
  onAcceptTerms: () => void;
  onDenyTerms: () => void;
  updateLoading?: boolean;
};
const Footer = ({
  className,
  onAcceptTerms,
  onDenyTerms,
  updateLoading = false,
}: Props) => {
  return (
    <div className="h-[70px]">
      <div
        className={cn(
          'sm:px-4 md:px-28 lg:px-0 flex justify-between w-full items-center py-2',
          className
        )}>
        <Button
          label="Deny"
          labelStyle="text-base font-normal text-black"
          className="bg-veryLightGrey rounded-xl"
          onClick={onDenyTerms}
        />
        <Button
          label={
            updateLoading ? (
              <Spinner fillColor="fill-white" width={36.5} />
            ) : (
              'Accept'
            )
          }
          className="rounded-xl bg-cashiaBlue"
          labelStyle="text-neutral-50 text-base font-normal"
          onClick={onAcceptTerms}
        />
      </div>
    </div>
  );
};

export default Footer;
