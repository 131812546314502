import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Checkbox from '../../../components/tailwind/Checkbox';
import Spinner from '../../../components/tailwind/Spinner';
import {
  MerchantOnboardingInput,
  useGetMerchantCategoriesQuery,
} from '../../../graphql/generated';
import Footer from '../components/Footer';
import {useSelfOnboardingContentContext} from '../layout';

const TypeOfStore = () => {
  const {merchant, update, updateLoading} = useSelfOnboardingContentContext();
  const {data, loading} = useGetMerchantCategoriesQuery();
  const [selectedCatId, setselectedCatId] = useState('');
  const navigate = useNavigate();

  const merchantOnboardingInput = useMemo(() => {
    const input: MerchantOnboardingInput = {
      merchantCategories: [selectedCatId],
    };
    return input;
  }, [selectedCatId]);

  const updateMerchant = async () => {
    try {
      await update?.(merchantOnboardingInput);
      navigate('/thanks');
    } catch (error) {
      console.error('Error updating merchant:', error);
    }
  };
  useEffect(() => {
    setselectedCatId(merchant?.merchantCategories?.[0]?.id || '');
  }, [merchant]);
  return (
    <div className="flex flex-col w-full h-full">
      <div className="lg:p-24 lg:pt-14 md:p-9 p-5 w-full flex-1 overflow-y-scroll no-scrollbar">
        <div className="flex flex-col gap-5 h-full pt-0 lg:pt-10">
          <div className="flex flex-col gap-2 mb-4">
            <h1 className="text-neutral-800 text-xl font-semibold">
              What type of store are you setting up?
            </h1>
            <p className="text-zinc-700 text-base font-normal leading-tight">
              Select the type of store you want to set up on Cashia.You will be
              able to add more options after you publish.
            </p>
          </div>
          <div className="flex flex-col">
            {loading ? (
              <Spinner className="h-[50px] w-full mt-5 md:mt-10" />
            ) : (
              <>
                {data?.merchantCategories.map((category) => (
                  <div
                    key={category.id}
                    className="flex justify-between items-center py-5 border-b-2 border-b-neutral-200">
                    <p className="text-neutral-800 text-lg font-normal leading-tight">
                      {category.name}
                    </p>
                    <Checkbox
                      checked={selectedCatId === category.id}
                      onChange={() => setselectedCatId(category.id)}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer
        className="flex-5"
        update={updateMerchant}
        updateLoading={updateLoading}
      />
    </div>
  );
};

export default TypeOfStore;
