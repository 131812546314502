import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import Footer from './components/footer';
import {ReactComponent as Logo} from '../../assets/logos/cashia_logo.svg';
import {useAgreedToTermsMutation} from '../../graphql/generated';
import TermsAndConditions from '../../utils/Terms&Conditions';
import {useAuth} from '../../utils/auth';
import {useUserAuth} from '../../utils/user';
import './index.css';

const Terms = () => {
  const [activeTab, setActiveTab] = useState<string>('tab_item_0');
  const {merchant} = useUserAuth();
  const {logout} = useAuth();
  const navigate = useNavigate();
  const showFooter = !merchant?.agreedToTerms;

  const [agreeToTermsMutation, {loading: agreeToTermsLoading}] =
    useAgreedToTermsMutation();

  const handleAgreeToTerms = () => {
    agreeToTermsMutation({
      variables: {
        id: merchant?.id || '',
        input: {
          agreedToTerms: true,
        },
      },
    })
      .then(() => {
        navigate('/store');
      })
      .catch((error) => {
        console.error('An error occurred while agreeing to terms.', error);
      });
  };

  const handleDenyTerms = useCallback(() => {
    logout()
      .then(() => {
        navigate('/register');
      })
      .catch((error) => {
        console.error('An unexpected error occurred. Please try again.', error);
      });
  }, [logout, navigate]);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const scrollOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'start',
    };

    const scrollToTabContent = () => {
      const element = document.getElementById(activeTab);
      if (element) {
        element.scrollIntoView(scrollOptions);
      }
    };

    scrollToTabContent();
  }, [activeTab]);

  return (
    <div className="w-screen h-screen overflow-x-hidden overflow-hidden">
      <div className="flex items-center justify-center lg:justify-between lg:p-4">
        <Logo className="sm:hidden lg:flex cursor-pointer mx-40" />
        <h1 className="sm:text-sm md:text-lg lg:text-lg font-normal lg:font-semibold text-black lg:mr-96 pt-10">
          MERCHANT&apos;S GENERAL TERMS & CONDITIONS
        </h1>
      </div>
      <div className="flex justify-center items-center">
        <div className="tc_wrap h-[80svh] w-[100svw] lg:w-[80svw] flex max-w-[100%] overflow-hidden rounded">
          <div className="tabs_list w-[200px] bg-veryLightGrey h-[80svh] text-wallGrey hidden lg:flex rounded">
            <ul>
              {TermsAndConditions.map((term) => (
                <li
                  key={term.id}
                  onClick={() => handleTabClick(term.id)}
                  className={`cursor-pointer text-[12px] ${
                    activeTab === term.id ? 'active' : ''
                  }`}>
                  {term.title}
                </li>
              ))}
            </ul>
          </div>
          <div className="tabs_content bg-veryLightGrey lg:mx-4 rounded">
            <div className="tab_body text-[15px] h-full overflow-auto">
              {TermsAndConditions.map((point) => (
                <div
                  key={point.id}
                  id={point.id}
                  className={`tab_item ${point.id} ${
                    activeTab === point.id ? 'active' : ''
                  }`}>
                  <h3 className="text-cashiaBlue">{point.title}</h3>
                  {point.content.map((paragraph, index) => (
                    <p key={index} className="mb-4">
                      {paragraph}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="sm:mx-[20px] lg:mx-[150px]">
        {showFooter ? (
          <Footer
            onAcceptTerms={handleAgreeToTerms}
            onDenyTerms={handleDenyTerms}
            updateLoading={agreeToTermsLoading}
          />
        ) : (
          <div className="flex justify-end sm:mx-4 md:mx-16 lg:mx-0 my-4">
            <button
              onClick={() => navigate(-1)}
              className="back-button bg-cashiaBlue text-white px-4 py-2 rounded-md">
              Go Back
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Terms;
