import {toast, Sheet} from '@cashiaApp/web-components';
import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as ArrowBackIcon} from '../../assets/icons/back_arrow_black.svg';
import Button from '../../components/tailwind/Button';
import Modal from '../../components/tailwind/Modal';
import {
  OperatingTimeFieldsFragment,
  useUpdateMerchantMutation,
} from '../../graphql/generated';
import {days} from '../../utils/constants';
import {cn, convertTo12HourFormat} from '../../utils/reusableFunctions';
import {DayOfWeek} from '../../utils/types';
import {useUserAuth} from '../../utils/user';

type DayType = {
  title: string;
  display: string;
  key: string;
};

type OperatingDayTime = {
  dayKey?: string;
  startTime?: string;
  endTime?: string;
};

type CardItems = {
  openingHours?: string;
  closingHours?: string;
  days: Array<DayType>;
};

type Duration = {
  from?: string;
  to?: string;
};

type OperatingTime = {
  [key: string]: Duration;
};

export const genCardItems = (
  operatingTimes?: OperatingTimeFieldsFragment
): CardItems[] | undefined => {
  const obj: Record<string, DayOfWeek[]> = {};
  if (!operatingTimes) return;
  Object.entries(operatingTimes).forEach(([key, value]) => {
    if (value === 'OperatingTime' || key === '__typename') return;
    value?.map((item) => {
      if (item?.from && item?.to && item?.from) {
        obj[item?.from + item?.to] = obj[item?.from + item?.to]?.concat([
          key as DayOfWeek,
        ]) || [key as DayOfWeek];
      }
    });
  });
  if (!obj) return;
  return Object.entries(obj).map(([key, value]) => ({
    days: value.map((entry) => days.filter((day) => day.key === entry)).flat(),
    openingHours: key.slice(0, 5),
    closingHours: key.slice(5),
  }));
};

interface CustomTypographyProps {
  className?: string;
  children: React.ReactNode;
}

const CustomTypography: React.FC<CustomTypographyProps> = ({
  className,
  children,
}) => <p className={`text-24px ${className ?? ''}`}>{children}</p>;

const MobileViewOperatingHours = () => {
  const [updateMerchant] = useUpdateMerchantMutation();
  const [editingCardIndex, setEditingCardIndex] = useState<number | null>(null);
  const [editingOperatingDayHours, setEditingOperatingDayHours] =
    useState<OperatingDayTime>({
      dayKey: '',
      startTime: '00:00',
      endTime: '00:00',
    });
  const [selectedDays, setSelectedDays] = useState<DayType[]>([]);
  const [cards, setCards] = useState<CardItems[]>([]);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

  const {merchant} = useUserAuth();
  const merchantId = useMemo(() => merchant?.id, [merchant]);

  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const operatingHours = useMemo(
    () => merchant?.operatingTime,
    [merchant?.operatingTime]
  );

  useEffect(() => {
    if (!operatingHours) return;
    setCards(genCardItems(operatingHours) || []);
  }, [operatingHours]);

  const submitUpdate = useCallback(
    async (times: CardItems[]) => {
      const operatingTime: OperatingTime = {};

      times.forEach((item) => {
        item.days.forEach((day) => {
          operatingTime[day.key] = {
            from: item.openingHours,
            to: item.closingHours,
          };
        });
      });

      await updateMerchant({
        variables: {
          id: merchantId || '',
          input: {
            operatingTime,
          },
        },
      });
    },
    [updateMerchant, merchantId]
  );

  const hasDays = useMemo(() => !!selectedDays.length, [selectedDays]);

  const onSelectDayChange = useCallback(
    (item: DayType) => {
      const existingIndex = selectedDays.findIndex(
        (day) => day.title === item.title
      );

      if (existingIndex > -1) {
        setSelectedDays((old) => [
          ...old.slice(0, existingIndex),
          ...old.slice(existingIndex + 1),
        ]);
      } else {
        setSelectedDays((old) => [...old, item]);
      }
    },
    [selectedDays]
  );

  const isChecked = useCallback(
    (item: DayType) => {
      const exists = selectedDays.findIndex((day) => day.title === item.title);
      return exists > -1;
    },
    [selectedDays]
  );

  const addDayTime = useCallback(() => {
    if (!hasDays) {
      return;
    }
    const newCard = {
      openingHours: editingOperatingDayHours.startTime,
      closingHours: editingOperatingDayHours.endTime,
      days: selectedDays,
    };
    const newCards = [...cards, newCard];
    setCards(newCards);
    void submitUpdate(newCards);
    toast.success('Operating hours created successfully');

    setEditingOperatingDayHours({
      dayKey: '',
      startTime: '00:00',
      endTime: '00:00',
    });
    setSelectedDays([]);
  }, [
    hasDays,
    cards,
    editingOperatingDayHours.startTime,
    editingOperatingDayHours.endTime,
    selectedDays,
    submitUpdate,
  ]);

  const editCard = (index: number) => {
    const card = cards[index];
    setEditingCardIndex(index);
    setEditingOperatingDayHours({
      startTime: card?.openingHours,
      endTime: card?.closingHours,
    });
    setSelectedDays(card?.days ?? []);
  };

  const updateCard = (index: number) => {
    const newCards = [...cards];
    newCards[index] = {
      openingHours: editingOperatingDayHours.startTime,
      closingHours: editingOperatingDayHours.endTime,
      days: selectedDays,
    };
    setCards(newCards);
    setEditingCardIndex(null);
    void submitUpdate(newCards);
  };

  const deleteCard = async (index: number) => {
    setDeleteModalOpen(false);
    if (index === undefined) return;

    const updatedCards = [...cards];
    updatedCards.splice(index, 1);
    setCards(updatedCards);

    try {
      await updateMerchant({
        variables: {
          id: merchantId || '',
          input: {
            operatingTime: null,
          },
        },
      });
      await submitUpdate(updatedCards);

      toast.success('Operating hours deleted successfully');
    } catch (err) {
      toast.error('Error deleting operating hours:');
      setCards(cards);
    }
  };

  const availableDays = useMemo(() => {
    const selectedKeys = new Set(
      cards.flatMap((card) => card.days).map((day) => day.key)
    );
    return days.filter((day) => !selectedKeys.has(day.key));
  }, [cards]);

  return (
    <Sheet>
      <div className="flex flex-col w-full min-h-screen overflow-y-auto">
        <main className="px-4 w-full flex-1 flex flex-col gap-7 overflow-y-auto pb-16">
          <div className="flex items-center gap-10 border-b-[1px] ">
            <button
              className="flex py-5 items-center"
              onClick={() => navigate('/profile')}>
              <ArrowBackIcon />
            </button>
            <CustomTypography className=" mt-1 text-lg">
              Operating hours
            </CustomTypography>
          </div>

          <h1 className="text-neutral-800 text-xl font-semibold">
            Operating hours
          </h1>
          <div className="flex gap-7 flex-wrap">
            {cards?.map((card, i) => (
              <div
                key={i}
                className={cn(
                  'w-full px-5 pb-5 pt-3 flex flex-col gap-5 bg-neutral-50 rounded shadow-md border border-zinc-300'
                )}>
                {editingCardIndex === i ? (
                  <>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-3 flex-wrap">
                        {days.map((day) => (
                          <div
                            key={day.key}
                            className={cn(
                              'w-7 h-7 p-3 flex justify-center items-center text-[12px] cursor-pointer rounded-full',
                              isChecked(day)
                                ? 'bg-cashiaBlue text-white'
                                : 'bg-gray-200'
                            )}
                            onClick={() => onSelectDayChange(day)}>
                            {day.display}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex items-center justify-between gap-5 mt-1">
                      <div className="flex items-center gap-5">
                        <div className="flex flex-col gap-2 mt-2">
                          <p className="text-zinc-700 text-[13px] font-normal font-['Metropolis']">
                            Opening hours
                          </p>
                          <input
                            type="time"
                            value={editingOperatingDayHours.startTime}
                            className="border border-gray-300 rounded px-1 text-foggy focus:border-red-500 focus:outline-none focus:ring-0"
                            onChange={(e) =>
                              setEditingOperatingDayHours((prev) => ({
                                ...prev,
                                startTime: e.target.value,
                              }))
                            }
                          />
                        </div>

                        <div className="flex flex-col gap-2 mt-2 border-l-2 border-stone-200 pl-6 ">
                          <p className="text-zinc-700 text-[13px] font-normal font-['Metropolis']">
                            Closing hours
                          </p>

                          <input
                            type="time"
                            value={editingOperatingDayHours.endTime}
                            className="border border-gray-300 rounded px-1 text-foggy focus:border-red-500 focus:outline-none focus:ring-0"
                            onChange={(e) =>
                              setEditingOperatingDayHours((prev) => ({
                                ...prev,
                                endTime: e.target.value,
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between gap-2 pt-1">
                      <button
                        className="text-smoothRed text-[13px] font-medium"
                        onClick={() => updateCard(i)}>
                        Save
                      </button>
                      <button
                        className="underline text-[13px] font-medium"
                        onClick={() => {
                          setDeleteIndex(i);
                          setDeleteModalOpen(true);
                        }}>
                        Delete
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex ">
                      <div className="flex gap-2 flex-wrap">
                        {card.days.map((day) => (
                          <div
                            key={day.key}
                            className="text-cyan-500 text-[12px] w-7 h-7 p-3 bg-gray-100 rounded-full font-medium font-['Metropolis'] flex items-center justify-center">
                            {day.display}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="flex items-center justify-between gap-5 mt-1">
                      <div className="flex gap-5">
                        <div className="flex flex-col pr-5 border-r-2 border-stone-200 gap-2">
                          <p className="text-zinc-700 text-[12px] font-normal font-['Metropolis']">
                            Opening hours
                          </p>
                          <p className="text-neutral-800 text-2xl font-medium font-['Metropolis']">
                            {convertTo12HourFormat(card.openingHours || '')}
                          </p>
                        </div>

                        <div className="flex flex-col gap-2">
                          <p className="text-zinc-700 text-[13px] font-normal font-['Metropolis']">
                            Closing hours
                          </p>
                          <p className="text-neutral-800 text-2xl font-medium font-['Metropolis']">
                            {convertTo12HourFormat(card.closingHours || '')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between gap-2 pt-1">
                      <button
                        className=" text-smoothRed text-[13px]  font-normal"
                        onClick={() => editCard(i)}>
                        Edit
                      </button>
                      <button
                        className="underline text-[13px] font-medium"
                        onClick={() => {
                          setDeleteIndex(i);
                          setDeleteModalOpen(true);
                        }}>
                        Delete
                      </button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>

          <h2 className="text-neutral-500 text-base font-medium">
            Add new hours
          </h2>
          <div className="w-full flex flex-col gap-0 flex-wrap rounded-[10px] shadow border border-stone-300 py-[10px] px-5">
            <div className="flex flex-row flex-wrap">
              <label htmlFor="opHours" className="flex-1 pr-5 cursor-pointer`">
                <p className="text-neutral-800 mt-3 mb-1 text-[13px] font-semibold">
                  Opening hours
                </p>
                {availableDays.length > 0 ? (
                  <input
                    type="time"
                    value={editingOperatingDayHours.startTime}
                    onFocus={(e) => e.target.showPicker()}
                    className="border border-gray-300 rounded px-1 text-gray-300 focus:outline-none focus:ring-0"
                    onChange={(e) =>
                      setEditingOperatingDayHours((prev) => ({
                        ...prev,
                        startTime: e.target.value,
                      }))
                    }
                  />
                ) : (
                  <p className="font-normal text-wallGrey  text-[13px] ">
                    Unavailable
                  </p>
                )}
              </label>
              <label
                htmlFor="clHours"
                className="flex-1 border-l-2 mt-3 border-stone-200 px-4 cursor-pointer">
                <p className="text-neutral-800 text-[13px] font-semibold mb-1">
                  Closing hours
                </p>
                {availableDays.length > 0 ? (
                  <input
                    type="time"
                    value={editingOperatingDayHours.endTime}
                    onFocus={(e) => e.target.showPicker()}
                    className="border border-gray-300 rounded px-1 text-gray-300 focus:outline-none focus:ring-0"
                    onChange={(e) =>
                      setEditingOperatingDayHours((prev) => ({
                        ...prev,
                        endTime: e.target.value,
                      }))
                    }
                  />
                ) : (
                  <p className="font-normal text-[13px] text-wallGrey">
                    Unavailable
                  </p>
                )}
              </label>
            </div>

            <div className="flex-1 mt-5 flex flex-wrap items-center justify-center">
              <div className="flex flex-col">
                <p className="text-neutral-800 text-[13px] text-center mb-1 font-semibold">
                  Select Opening Hours
                </p>

                {availableDays.length > 0 ? (
                  <div className="flex justify-center items-center p-1 gap-2 flex-row">
                    {availableDays.map((day) => (
                      <div
                        key={day.key}
                        className={cn(
                          'w-7 h-7 flex text-[12px] font-normal justify-center items-center m-1 cursor-pointer rounded-full',
                          isChecked(day)
                            ? 'bg-cashiaBlue text-white'
                            : 'bg-gray-200'
                        )}
                        onClick={() => onSelectDayChange(day)}>
                        {day.display}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="font-normal text-wallGrey text-center text-[13px] ">
                    All days have been assigned
                  </p>
                )}
              </div>
            </div>
            <div className="flex-1 flex justify-center mt-2 items-center">
              <Button
                label={
                  <div className="text-center text-neutral-50 text-base font-normal">
                    Add +
                  </div>
                }
                fullWidth
                className={`w-[250px] h-[46px] py-3 rounded-[10px] ${
                  hasDays ? 'bg-cashiaBlue' : 'bg-greyish'
                }`}
                disabled={!hasDays}
                onClick={addDayTime}
              />
            </div>
          </div>
        </main>

        <div className=" mx-20 mb-20 flex flex-col">
          <Modal
            isVisible={deleteModalOpen}
            onClose={() => setDeleteModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className="absolute top-1/2 left-1/2 transform flex flex-col items-center justify-center -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-xl shadow w-[300px] h-[226px]">
              <div className="flex flex-col items-center justify-center">
                <p className="font-medium mb-2 text-center text-[23px]">
                  Delete Operating Hours
                </p>
                <p className="text-center text-wallGrey mb-3 ">
                  Clicking `Delete` will erase the operating hours permanently.
                  This action is irreversible.
                </p>
                <div
                  className="w-[208px] mx-4 h-10 rounded-lg bg-sunsetBlue text-white cursor-pointer flex items-center justify-center"
                  onClick={() => deleteCard(deleteIndex || -1)}>
                  Delete Operating Hours
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Sheet>
  );
};

export default MobileViewOperatingHours;
